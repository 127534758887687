import React from "react";
import Layout from "../components/layouts";

export default () => (
  <Layout>
    <div className="not-found">
      <h1>404</h1>
      <h2>Document not found</h2>
      <p>
        <a href="/">Return to homepage</a>
      </p>
    </div>
  </Layout>
);
